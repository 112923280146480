import React from 'react';
import checkImage from '../img/services-check.png';

const ServiceChecklistItem = ({ title, body }) => {
  return (
    <div className="columns is-mobile">
      <div className="column is-narrow">
        <img src={checkImage} alt="Checkmark" />
      </div>
      <div className="column service-checklist-item">
        <h3>{title}</h3>
        <p>{body}</p>
      </div>
    </div>
  );
};

export default ServiceChecklistItem;
