import React from 'react';
import { withPrefix } from 'gatsby';

const ReviewBar = () => {
  return (
    <section className="section">
      <div className="container">
        <div className="content has-text-centered">
          <div className="columns">
            <div className="column is-3">
              <a
                href="https://www.facebook.com/pg/OrganizedTransitionsMoveManagement/reviews/?ref=page_internal"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={`${withPrefix('/')}img/FacebookReviewsButton.jpg`}
                  alt="Leave a Facebook review"
                />
              </a>
            </div>
            <div className="column is-3">
              <a
                href="https://www.google.com/search?q=organized+transitions&oq=organized+transition&aqs=chrome.0.35i39j69i60l2j69i57j69i60j0.2595j0j7&sourceid=chrome&ie=UTF-8#lrd=0x882c849d537dda53:0xcfd4dd0d542ee787,1,,,"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={`${withPrefix(
                    '/'
                  )}img/Google-Reviews-icon-300x150-300x150.png`}
                  alt="Leave a Google review"
                />
              </a>
            </div>
            <div className="column is-3">
              <a
                href="https://homestars.com/companies/2802216-organized-transitions"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={`${withPrefix('/')}img/homestars-300x171-300x171.png`}
                  alt="Leave a HomeStars review"
                />
              </a>
            </div>
            <div className="column is-3">
              <a
                href="https://maxsold.com/shop/organizedtransitions"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={`${withPrefix('/')}img/OT-Max.jpg`}
                  alt="View current and past auctions"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ReviewBar;
