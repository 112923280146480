import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { v4 } from 'uuid';

import BannerHeader from '../components/BannerHeader';
import Content, { HTMLContent } from '../components/Content';
import Layout from '../components/Layout';
import LearnHero from '../components/LearnHero';
import ReviewBar from '../components/ReviewBar';
import SEO from '../components/SEO';
import ContactCallToAction from '../components/ContactCallToAction';
import ServiceChecklistItem from '../components/ServiceChecklistItem';

export const ServicePageTemplate = ({
  title,
  metaDescription,
  header,
  content,
  contentComponent,
  checklist,
  slug,
}) => {
  const PageContent = contentComponent || Content;
  return (
    <div>
      <SEO title={title} pathname={slug} desription={metaDescription} />
      <BannerHeader
        title={title}
        subtitle={header.subtitle}
        image={header.image}
      />
      <section className="section">
        <div className="container content markdown-content">
          <div className="columns">
            <div className="column is-6">
              <PageContent className="markdown-content" content={content} />
            </div>
            <div className="column is-6 service-checklist">
              {checklist.map((item) => (
                <ServiceChecklistItem
                  key={v4()}
                  title={item.title}
                  body={item.body}
                />
              ))}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

ServicePageTemplate.propTypes = {
  title: PropTypes.string,
  metaDescription: PropTypes.string,
  header: PropTypes.shape({
    image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    subtitle: PropTypes.string,
  }),
  content: PropTypes.string,
  contentComponent: PropTypes.func,
  checklist: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      body: PropTypes.string,
    })
  ),
  slug: PropTypes.string,
};

const ServicePage = ({ data }) => {
  const { markdownRemark } = data;
  return (
    <Layout>
      <ServicePageTemplate
        contentComponent={HTMLContent}
        content={markdownRemark.html}
        {...markdownRemark.frontmatter}
        slug={markdownRemark.fields.slug}
      />
      <ContactCallToAction />
      <LearnHero />
      <ReviewBar />
    </Layout>
  );
};

ServicePage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ServicePage;

export const pageQuery = graphql`
  query ServicePageById($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
        metaDescription
        header {
          subtitle
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        checklist {
          title
          body
        }
      }
      fields {
        slug
      }
    }
  }
`;
